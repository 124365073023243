import { createContext, ReactNode, useState } from "react"

export interface ILoginContext {
  name: string
  jwt: string
  perfil: string
  empresa: string
  email: string
  empresaId: string
  pesquisaRespondida: boolean,
  popupVbg:any

  setLogin: (_name: string, _perfil : string, _jwt : string, _empresa : string, _email : string, _empresaId : string, pesquisaRespondida: boolean,popupVbg:any) => Promise<void>
  setLogout: () => Promise<void>

}

export const LoginContext = createContext<ILoginContext>(
  {} as ILoginContext
)

export function LoginContextProvider({
  children,
}: {
  children: ReactNode
}) {
  const [name, setName] = useState("")
  const [jwt, setJwt] = useState("")
  const [perfil, setPerfil] = useState("")
  const [empresa, setEmpresa] = useState("")
  const [email, setEmail] = useState("")
  const [empresaId, setEmpresaId] = useState("")
  const [pesquisaRespondida, setPesquisaRespondida] = useState(false)
  const [popupVbg, setPopupVbg] = useState(undefined)

  async function setLogin(_name: string, _perfil:string, _jwt: string, _empresa : string, _email : string, _empresaId : string, _pesquisaRespondida: boolean, _popupVbg:any) 
  {
    setName(_name);
    setJwt(_jwt);
    setPerfil(_perfil);
    setEmpresa(_empresa);
    setEmail(_email);
    setEmpresaId(_empresaId);
    setPesquisaRespondida(_pesquisaRespondida)
    setPopupVbg(_popupVbg)
    
  }

  async function setLogout() {
    setName("");
    setJwt("");
    setPerfil("")
    setEmail("")
    setEmpresa("")
    setEmpresaId("")
    setPesquisaRespondida(false)
    setPopupVbg(undefined)
  }


  return (
    <LoginContext.Provider
      value={{ jwt, name, perfil, empresa, email, empresaId, setLogin, setLogout, pesquisaRespondida, popupVbg}}
    >
      {children}
    </LoginContext.Provider>
  )
}
