import {
  CPIDValidateKeyFunction,
  GIAIValidateKeyFunction,
  GINCValidateKeyFunction,
  GDTIValidateKeyFunction,
  SSCCValidateKeyFunction,
  GSRNvalidateKeyFunction,
  GRAIValidateKeyFunction,
  GCNValidateKeyFunction,
  GMNValidateKeyFunction,
  GSINValidateKeyFunction
} from './functionsOtherKeys'

export type KeyTypeVerified = {
  size: number;
  typeKey: string;
  variableLength: boolean; 
  compareCode: (value: string) => any;
  description: string;
};

const CPIDValidateKey = function (this: KeyTypeVerified, value: string) {
 return CPIDValidateKeyFunction(value);
};

const GIAIValidateKey = function (this: KeyTypeVerified, value: string) {
  return GIAIValidateKeyFunction(value);
};

const GINCValidateKey = function (this: KeyTypeVerified, value: string) {
  return GINCValidateKeyFunction(value);
};


const GDTIValidateKey = function (this: KeyTypeVerified, value: string) {
  return GDTIValidateKeyFunction(value);
};

const SSCCValidateKey = function (this: KeyTypeVerified, value: string) {
  return SSCCValidateKeyFunction(value);
};

const GSRNvalidateKey = function (this: KeyTypeVerified, value: string) {
  return GSRNvalidateKeyFunction(value)
};

const GRAIValidateKey = function (this: KeyTypeVerified, value: string) {
   return GRAIValidateKeyFunction(value);
};


const GSINValidateKey = function (this: KeyTypeVerified, value: string) {
  return GSINValidateKeyFunction(value);
};

const GCNValidateKey = function (this: KeyTypeVerified, value: string) {
  return GCNValidateKeyFunction(value);
};

const GMNValidateKey = function (this: KeyTypeVerified, value: string) {
  return GMNValidateKeyFunction(value);
};

export const KeyTypesVerified: { [key: string]: KeyTypeVerified } = {
  SSCC: {
    size: 18,
    typeKey: "SSCC",
    variableLength: false,
    compareCode: SSCCValidateKey,
    description : "SSCC - Identificação de Unidades Logísticas"
  },
  GRAI: {
    size: 14,
    typeKey: "GRAI",
    variableLength: false,
    compareCode: GRAIValidateKey,
    description : "GRAI - Identificador Global de Ativo Retornável"
  },
  GIAI: {
    size: 13,
    typeKey: "GIAI",
    variableLength: true,
    compareCode: GIAIValidateKey,
    description : "GIAI - Identificador Global de Ativo Individual"
  },
  GSIN: {
    size: 17,
    typeKey: "GSIN",
    variableLength: false,
    compareCode: GSINValidateKey,
    description : "GSIN - Número Global de Identificação de Carga"
  },
  GINC: {
    size: 13,
    typeKey: "GINC",
    variableLength: true, 
    compareCode: GINCValidateKey,
    description : "GINC - Número Global de Identificação de Consignação"
  },
  GSRN: {
    size: 18,
    typeKey: "GSRN",
    variableLength: false,
    compareCode: GSRNvalidateKey,
    description : "GSRN - Número Global da Relação de Serviço"
  },
  GDTI: {
    size: 13,
    typeKey: "GDTI",
    variableLength: true, 
    compareCode: GDTIValidateKey,
    description : "GDTI - Identificador Global do Tipo de Documento"
  },
  GCN: {
      size: 13,
      typeKey: "GCN",
      variableLength: true, 
      compareCode: GCNValidateKey,
      description : "GCN - Número de Cupom Global"
  },
  CPID: {
      size: 30,
      typeKey: "CPID",
      variableLength: true, 
      compareCode: CPIDValidateKey,
      description : "CPID - Identificador de Componente/Peça"
  },
  GMN: {
      size: 25,
      typeKey: "GMN",
      variableLength: true, 
      compareCode: GMNValidateKey,
      description : "GMN - Número do Modelo Global"
  },
};

