import React, { useState, useContext, useEffect, useRef, KeyboardEvent } from 'react';
import { showError, showWait, dismissToast } from '../../../../services/toast';
import * as xlsx from "xlsx"
import { LoginContext } from '../../../../contexts/login_context';
import DataTable, { TableColumn } from 'react-data-table-component';
import Warning from '../../../../components/Warning';
import BootyPagination from '../../../../components/Pagination';
import { IoMdClose } from 'react-icons/io';
import Modal from "react-modal";
import { useSelector } from "../../../../native-state.dev";
import { getOtherKeys } from '../../../../services/getOtherKeys';
import { processOtherKeyData, processValidationErrors } from '../../../../services/other_keys_process';
import OtherKeyResults from '../../../../components/OtherKeysResult';
import { exportFileFrontEndOtherKey, exportFileFrontEndOtherKeyError, exportFileTestOtherKey } from '../../../../services/exportFileFrontEndOtherKeys';
import { KeyTypesVerified } from '../../../../helpers/keyTypes';
import './style.css'


interface companyData {
  image: string,
  gln: string,
  nomeEmpresa: string,
  tipoGln: string,
  endereco: string,
  enderecoDigital: string,
  hasError: boolean,
  multiple: boolean,
  lastmultiple: boolean,
  detail: any
}

interface DataRow {
  image: string;
  gln: string;
  nomeEmpresa: string;
  tipoGln: string;
  endereco: string;
  enderecoDigital: string;
  hasError: boolean;
  multiple: boolean
  lastmultiple: boolean
}


const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  }
}

const SearchManyOtherKeys = () => {
  

  const [item, setItem] = useState({ error: false, content: false });
  let maxProduct = 0
  const no_image_url = '/jam.png';
  const ico_complete_product = '/vbg-horizontal.png';
  const { jwt } = useContext(LoginContext);
  const [name, setName] = useState(null);
  const [v, setV] = useState("");
  const [codes, setCodes] = useState<string[]>([]);
  const [exportCodes, setexportCodes] = useState<string[]>([]);
  const [startSearch, setStartSearch] = useState(false);
  const [code, setCode] = useState("");
  const unknown_text = 'Não cadastrado';
  const max_gtins_per_search = 100;
  const [isChecked, setChecked] = useState(false);
  const [details, setDetails] = useState<any>(null);
  const [view, setView] = useState(false);
  const [view2, setView2] = useState({ error: false, content: false });
  const [hasSearchError, setHasSearchError] = useState(false);
  const [stateModal, setStateModal] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [keyType, setKeyType] = useState<any>(null);

  const [extension, setExtension] = useState('');

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const myRef: any = useRef();

  const [isFocused, setFocused] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false);
  const [CurrentValueSelect, setCurrentValueSelect] = useState<string>('')
  
   
  let companyData = [{
    image: no_image_url,
    gln: unknown_text,
    nomeEmpresa: unknown_text,
    tipoGln: unknown_text,
    endereco: unknown_text,
    enderecoDigital: unknown_text,
    hasError: false,
    multiple: false,
    lastmultiple: false
  }];



  const [arrCompanies, setArrCompanies] = useState(companyData);
  const [originalArr, setOriginalArr] = useState(companyData);
  const [dataPrepared, setdataPrepared] = useState<any[]>([]);

  const help_link_gs1 = 'Por favor, verifique erros de digitação e GS1 Brasil se precisar de ajuda.';

  //Modal
  const open = () => {

    setExportButtonStatus(false);
    setStateModal(true)
    setExtension('')
  }

  const close = () => {
    setStateModal(false)
  }

  const handleChangeExtension = (event: any) => {
    setExtension(event.target.value)
  }

  const downloadFile = (product: any, format: string, name: string): void => {

    let format_type: string;
    let extension: string;

    switch (format) {
      case 'xlsx':
        format_type = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        extension = '.xlsx';
        break;

      case 'xls':
        format_type = 'application/vnd.ms-excel';
        extension = '.xls';
        break;

      case 'csv':
        format_type = 'text/csv';
        extension = '.csv';
        break;

      default:
        format_type = 'text/csv';
        extension = '.txt';
        break;
    }

    let file64base = `data:application/application/${format_type};base64, ${product}`;
    let a = document.createElement("a");
    a.href = file64base;
    a.download = `${name}.${extension}`;
    a.click();
  }
  const [modalObje,setState] = useSelector((s: { searchDone: any; })=>s.searchDone);

  const [exportButtonStatus, setExportButtonStatus] = useState(false);
  const exportFiles = async () => {
    setExportButtonStatus(true);

    let toastId = showWait('Aguarde, download em andamento.');

    if (extension !== '') {
      setState({searchDone:false});

      // const product: any = await exportFile(exportCodes, jwt, `${extension.toUpperCase()}`);

      // if (extension === 'xls') {
      //   downloadFile(product.data.xls, 'xlsx', 'planilha');
      // }
      // else if (extension === 'txt') {
      //   downloadFile(product.data.txt, 'txt', 'arquivo');
      // }
      // else {
      //   downloadFile(product.data.csv, 'csv', 'arquivo');
      // }
      exportFileTestOtherKey(dataPrepared, extension,maxProduct);

      setStateModal(false);


    }
    else {
      window.alert("Por favor, escolha uma extensão");
    }
    setExportButtonStatus(false);
    dismissToast(toastId);

  }

  const generatePlaceholder = () => {
    if (CurrentValueSelect) {
      return `Pesquise até 100 chaves de (${CurrentValueSelect}) por vez, digite aqui, separando por vírgulas, ou arraste e solte o arquivo (formatos: .xlsx, .txt, .csv) para adicionar as chaves (${CurrentValueSelect}).`;
    }
    return 'Pesquise até 100 chaves por vez, digite aqui, separando por vírgulas, ou arraste e solte o arquivo (formatos: .xlsx .txt, .csv) para adicionar as chaves';
  };


  useEffect(() => {

    function handleResize() {
      setWindowDimensions(getWindowDimensions)
    }

    window.addEventListener("resize", handleResize);



    return () => window.removeEventListener("resize", handleResize);


  }, [windowDimensions.height, windowDimensions.width])

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value; // A string selecionada (typeKey)
    setCurrentValueSelect(selectedValue);
    const selectedKeyType = KeyTypesVerified[selectedValue]; // Obtém o objeto completo baseado no typeKey

    // Apenas se o objeto existir no KeyTypesVerified
    if (selectedKeyType) {
      setKeyType(selectedKeyType); // Armazena o objeto completo no estado
    }
  }

  //Upload file
  async function handleGetFile(file: any) {
    try {
      const extensionTXT = String(file.name).match(/txt/g)
      if (extensionTXT?.includes("txt")) {
        const archive = await file.text();
        setV(archive);
        return
      }

      const extensionCSV = String(file.name).match(/csv/g)
      if (extensionCSV?.includes("csv")) {
        const archive = await file.text();
        setV(archive);
        return
      }

      //xls or xlsx
      const reader = new FileReader();

      reader.readAsArrayBuffer(file)
      reader.onload = (e) => {
        const archive = e.target?.result;
        const workbook = xlsx.read(archive, { type: "buffer" });
        const workSheetName = workbook.SheetNames[0];
        const workSheet = workbook.Sheets[workSheetName];

        const data = xlsx.utils.sheet_to_csv(workSheet).split("\n").filter((element: string | any[] | null) => {
          if (element != null && element.length > 0)
            return true;
        })

        setV(data.join(','));

      }
    }
    catch (error) {
      console.log(error)
    }
  }


  const search = async (code: any) => {
    var allvalid = true
    code.forEach((chave: string)=>{
      if(!keyType.compareCode(chave)){
        allvalid = false;
      }
    })
    if(!allvalid){  
      showError(`Chave com tamanho inválido por favor verifique`);
      return
    }

    let companyData: Array<companyData> = [];

    let toastId = showWait('Aguarde, enquanto realizamos sua pesquisa.');

    setView(false);

    const response: any = await getOtherKeys([code,keyType.typeKey], jwt);

    if (myRef && myRef.current)
      myRef.current.scrollIntoView();

    dismissToast(toastId);
    let data = response.data;
  

    let imageAux: string;
    let gln: string;
    let nomeEmpresa: string;
    let tipoGln: string;
    let endereco: any;
    let error: boolean;


    if (data) {

      setView(true);

      let has_error = [];
      let valid_glns: any[] = [];
      let auxCompanies: any[] = [];
      let auxCompaniesTobeProcessed: any[] = [];
      let errorsTobeProcessed: any[] = [];
      //validações

      data.forEach((item: any) => {
        if (!!item.validationErrors) {
          errorsTobeProcessed.push(item)
        } else {
          auxCompaniesTobeProcessed.push(item)
        }
      })
      auxCompaniesTobeProcessed.forEach((company: any) => {
        if(keyType){
          let companyProcessed = processOtherKeyData(company,keyType.typeKey);
          auxCompanies.push(companyProcessed)
        }
      })

      let auxDatePrepared: any[] = [];
      auxCompanies.forEach((element: any) => {
        if(keyType){
          let item = exportFileFrontEndOtherKey(element, keyType.typeKey);
          auxDatePrepared.push(item);
        }
      });
      

      errorsTobeProcessed.forEach((error: any) => {
        let item = exportFileFrontEndOtherKeyError(error);
        auxDatePrepared.push(item);
        auxCompanies = processValidationErrors(auxCompanies, error);
      })
      setdataPrepared(auxDatePrepared);
      setState({searchDone:true});


      auxCompanies.forEach((item: any) => {

        if (!!item.validationErrors) {

          imageAux = no_image_url;
          gln = item.gln;
          nomeEmpresa = "";
          tipoGln = "";
          endereco = "";
          error = true;
          has_error.push(1);

          companyData.push(
            {
              image: imageAux,
              gln: gln,
              nomeEmpresa: "Não foi possível encontrar esta chave",
              tipoGln: "",
              endereco: "",
              enderecoDigital: "",
              hasError: error,
              detail: item,
              multiple: false,
              lastmultiple: false,
            });


        }

        else {
          imageAux = no_image_url;
          gln = item.companyData.code;
          nomeEmpresa = item.companyData.nomeEmpresa;
          tipoGln = "Não Cadastrado";
          endereco = item.companyData.endereco;
          error = false;
          valid_glns.push(item.gln);
        

          
              let inseAux = true;
    

            
              companyData.push(
                {
                  image: imageAux,
                  gln: gln,
                  nomeEmpresa: nomeEmpresa,
                  tipoGln: tipoGln,
                  endereco: endereco,
                  enderecoDigital: "",
                  hasError: error,
                  detail: item,
                  multiple: false,
                  lastmultiple: false,
                });
            
          


        }

        setexportCodes(valid_glns);

        if (has_error.length > 0) {
          setHasSearchError(true);
        }
        else {
          setHasSearchError(false);
        }





      })

      setArrCompanies(companyData);
      setOriginalArr(companyData);
      setStartSearch(true);

    }
    else {
      dismissToast(toastId);
      // if (product.msg)
      //   showError(product.msg);
      // else
      //   showError(`Erro ao executar pesquisa.`);
    }

  }

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    send()
  }
  const handleKeyboardEvent = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      send()
    }
  };
  async function send() {

    let select: HTMLSelectElement | null = document.getElementById('selectId') as HTMLSelectElement
    let CurrentValueSelect = select.value

    if (name) {
     
        await search(codes);

      
    }
    else {
      if (v === '') {
        showError(`Por favor, informe ao menos um código para consulta.`);
        return
      }
      if(CurrentValueSelect === null || CurrentValueSelect === ''){
        showError(`Por favor, selecione uma chave GS1 para consulta`);
        return
      }
      if (v.split(",").length > max_gtins_per_search) {
        showError(`Somente é possível pesquisar até ${max_gtins_per_search} números. Foi requisitado ${v.split(",").length} números para consulta.`);
        return
      }

      await search(v.replace(/\s+/g, "").split(","));
    }
  }


  const handleChange = () => {
    setChecked(!isChecked);
  };


  useEffect(
    () => {
      let retorno: any[]
      retorno = originalArr.filter((d: any) => (true));

      if (code !== '') {
        retorno = retorno.filter((d: { gln: string | any[]; }) => (d.gln.indexOf(code) >= 0));
      }

      setArrCompanies(retorno);
    }, [code, isChecked, originalArr]);


  const findGtin = (code: string | undefined) => {
    if (code !== undefined) {
      setCode(code)
    }
  }


  const showDetails = (gln: string) => {
    setItem({ error: false, content: false });

    let company: any = originalArr.find((element: any) => {
      if (element.gln === gln)
        return true;
    })
    if(company.hasError){
      setItem({ error: true, content: false });

    }else{
      setItem({ error: false, content: true });

    }


    setDetails(company.detail);

  }


  const GlnLink = (row: any) => <div
    onClick={() => { showDetails(row.gln) }}
    style={{
      textDecoration: "underline",
      color: "#00799E",
      lineHeight: 2,
      cursor: "pointer"
    }}
  >
    {row.gln}
  </div>;


  const printAddres = (endFull: any) => {
    let end = Array.isArray(endFull)?endFull[0]:endFull
    let content: any[] = [];
    if (end == "Não cadastrado") {
      content.push("Não cadastrado")
    }
    if (end.postalName != undefined) {
      content.push(end.postalName.value);
      content.push(<br></br>);
    }
    if (end.streetAddress != undefined) {
      content.push(end.streetAddress.value);
      content.push(<br></br>);
    }
    if (end.streetAddressLine2 != undefined) {
      content.push(end.streetAddressLine2.value);
      content.push(<br></br>);
    }
    if (end.addressLocality != undefined) {
      content.push(end.addressLocality.value);
      content.push(<br></br>);
    }
    if (end.addressRegion != undefined) {
      content.push(end.addressRegion.value);
      content.push(<br></br>);
    }
    if (end.postalCode != undefined) {
      content.push(end.postalCode);
      content.push(<br></br>);
    }
    if (end.countryCode != undefined) {
      content.push(end.countryCode);
      content.push(<br></br>);
    }
    return content;
  }



  const AddresList = (row: any) =>
    <div className='rowGlnAddresmany'>
      {row.endereco != "Não cadastrado" && (
        printAddres(row.endereco)
      )}
      {row.endereco == "Não cadastrado" && (
        row.enderecoDigital
      )}
    </div>;

  const columns: TableColumn<DataRow>[] = [
   
    {
      name: 'Chave de identificação',
      selector: (row: { gln: any; }) => row.gln,
      sortable: true,
      wrap: true,
      center: true,
      cell: (row: any) => <GlnLink {...row} />,
      width: '240px'
    },
    {
      name: 'Nome da Empresa',
      selector: (row: { nomeEmpresa: any; }) => row.nomeEmpresa,
      maxWidth: '240px',
      wrap: true,
      sortable: true,
    },
    {
      name: 'GS1 Licensiante',
      selector: (row: { tipoGln: any; }) => row.tipoGln,
      maxWidth: '150px',
      wrap: true,
      sortable: true,
    },
    {
      name: 'Endereço',
      cell: (row: any) => <AddresList {...row} />,
      maxWidth: '350px',
      wrap: true,
      sortable: false,
    }

  ];



  const conditionalRowStyles = [
    {
      when: (row: { multiple: boolean; }) => row.multiple === true,
      style: {
        borderBottomWidth: "0px"
      },

    },
    {
      when: (row: { multiple: boolean; }) => row.multiple === false,
      style: {
        borderBottomWidth: "4px",
        borderBottomColor: "#F0F5FA"
      },

    },
    {
      when: (row: { lastmultiple: boolean; }) => row.lastmultiple === true,
      style: {
        borderBottomWidth: "4px",
        borderBottomColor: "#F0F5FA"
      },

    },

    {
      when: (row: { hasError: boolean; }) => row.hasError === true,
      style: {
        backgroundColor: '#FFDFDF',
        color: '#BA1B23',
        '&:hover': {
          backgroundColor: '#BA1B23',
          color: '#FFDFDF'
        },
      },
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '62px', // override the row height
        fontFamily: "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'",
        fontSize: "0.94rem",
        color: "#002c6c"
      },
    },
    headCells: {
      style: {
        paddingLeft: '6px', // override the cell padding for head cells
        paddingRight: '6px',
        fontFamily: "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'",
        fontSize: "0.94rem",
        color: "#002c6c"
      },
    },
    cells: {
      style: {
        paddingLeft: '6px', // override the cell padding for data cells
        paddingRight: '6px',
        fontWeight: "500 !important"
      },
    },
    pagination: {
      style: {
        color: "#002c6c",
        fontFamily: "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'",
        fontSize: '0.94rem',
        minHeight: '56px',
      },
    },
  };

  const paginationComponentOptions = {
    rowsPerPageText: 'Mostrar',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };




  return (
    <>


      {!details &&
        <>


          <div className='bg-color-ui-1 '>

            <div className='container customWithManyOther'>

              <div className='row bg-color-white  rounded-lg'>

                <div className='col-12 col-md-12 pr-5 mb-4 customPaddingKeys' style={{ marginTop: "-10px"}}>



                  <div className='row  px-2' style={{ marginBottom: "20px", margin:'6px' }}>

                    <div className='container customPadding'>
                    <div className='flex sm:flex-col bg-white rounded-lg' >

                          <div className='col-12 col-md-12 mb-2 p-0' >
                            <form onSubmit={onFormSubmit} >
                              <div style={{display:'flex',alignItems:'center', width:'100%'}}>
                                  <div style={{width:'100%'}} className="form-group mb-0">
                                                  <select
                                                      style={{height:'54px'}}
                                                      className="form-control"
                                                      id='selectId'
                                                      defaultValue={''}
                                                      onChange={handleSelectChange}
                                                      //onBlur={ValueOptions}
                                                  >
                                                          <option disabled value={''}>Selecione uma chave GS1</option>
                                                          {/* Gera os options dinamicamente a partir do KeyTypesVerified */}
                                                            {Object.keys(KeyTypesVerified).map((key) => (
                                                              <option key={key} value={key}>
                                                                {KeyTypesVerified[key].description}
                                                              </option>
                                                            ))}
                                                  </select>
                                      </div>

                              </div>
                            </form>
                            {/* <div style={{marginTop: "5px"}}>
                              <span className="little_text">
                                Exemplo:{" "}
                                <a href='javascript:;'>
                                  <span
                                    onClick={() => setCode("9506000140445")}
                                    className="link"
                                  >
                                    9506000140445
                                  </span>
                                </a>
                              </span>

                            </div> */}
                          </div>


                          <div ref={myRef}></div>

                          </div>
                    </div>

                    <textarea
                      onKeyDown={handleKeyboardEvent}
                      onChange={({ target }) => { setName(null); setV(target.value) }}
                      value={v}
                      onDrop={(e: { preventDefault: () => void; stopPropagation: () => void; dataTransfer: { files: FileList; clearData: () => void; }; }) => {
                        e.preventDefault()
                        e.stopPropagation()
                        if (e && e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                          handleGetFile(e.dataTransfer.files[0])
                          e.dataTransfer.clearData()
                        }
                      }}
                      className="area"
                      key={CurrentValueSelect} placeholder={generatePlaceholder()}
                      style={{
                        marginTop: "1rem",
                        padding: "0.5rem 1.5rem",
                        resize: "none",
                        height: "94px",
                        width: "100%",
                        border: "1px solid #888B8D",
                        borderRadius: "4px",
                        fontSize: "1rem",
                        fontWeight: "400",
                        color: "#454545",
                      }}
                    ></textarea>
                  </div>
                  {/* <span style={{ fontSize: "0.94rem" }}>
                    Exemplo:
                    <a
                      onClick={() => setV("9501049811196, 789919700000, 7899197000008, 7908048318725, 7891103000010, 7898964351015, 7898970964001, 7898996300401, 7898969356046, 7898969356022, 7898969356039")}
                      className="call_link"
                    >
                      9501049811196, 789919700000, 7899197000008, 7908048318725, 7891103000010, 7898964351015, 7898970964001, 7898996300401, 7898969356046, 7898969356022, 7898969356039
                    </a>
                  </span> */}
                  <div className='row pl-3' style={{ marginTop: "10px" }}>
                    <p>Upload de arquivo:</p>
                  </div>
                  <div className="buttonRowSearcManyCustom">
                    <label className='chooseFileCustom'>
                      {name || "Escolher arquivo"}
                      <input
                        onChange={(e: { target: { files: FileList | null; value: string; }; }) => {
                          if (e && e.target && e.target.files && e.target.files.length > 0) {
                            handleGetFile(e.target.files[0]);
                            e.target.value = '';
                          }
                        }}
                        style={{ opacity: "0", position: "absolute", zIndex: "-1" }}
                        accept=".csv,.txt,.xls, .xlsx"
                        type="file"
                        name=""
                        id=""
                      />
                    </label>

                    <div className='btnSearchMultipleCustom'>
                      <button
                        className='btn btn-primary buttonEspecialGLNCustom'
                        onClick={send}
                        id='botao'
                      >
                        Buscar
                      </button>

                    </div>

                  </div>
                </div>



              </div>

            </div>

          </div>


          <div ref={myRef}></div>

          {startSearch === true && (

            <>
              {view &&

                <div className='bg-color-ui-1 pb-5 wp-home'>

                  <div className='container'>

                    {hasSearchError &&

                      <div className='row bg-color-white  pt-4'>
                        <Warning
                          type='error'
                          showpick={false}
                          title={'Alguns resultados da busca apresentam erros.'}
                          text={help_link_gs1}
                        ></Warning>
                      </div>

                    }

                    <div className='row bg-color-white py-5 px-4 rounded-lg'>

                      <div className='row' style={{ width: "100%" }}>

                        <div className='col-12 col-md-7 col-lg-9'>
                          <h2 style={{ marginTop: 0, marginBottom: '0.5rem' }}>Resultado da Pesquisa Múltipla</h2>
                          <p className='little_text'>Clique no GLN para visualizar mais detalhes do produto.</p>
                        </div>

                        <div className='col-12 col-md-5 col-lg-3'>

                          <div className='buttons_headerinfos'>

                            <button onClick={() => window.print()}>
                              Imprimir
                            </button>

                            <button name='buttonExport' onClick={() => open()}>
                              Exportar
                            </button>

                          </div>
                        </div>

                      </div>

                    </div>

                    <div className='row bg-color-white pb-2 px-0'>

                      <div className='col-12 col-md-6 col-lg-4 mb-3 pl-1'>

                        <div className='inputSearch'>
                          <div className="searchbox">
                            <label className="container_my_input">

                              <input
                                placeholder="Buscar"
                                onChange={({ target }) => findGtin(target.value)}
                                value={code}
                                className="especial_input"
                                maxLength={14}
                              />
                            </label>
                            <button
                              onClick={() => findGtin(undefined)}
                              className="btnfind"
                            >
                              <img src="./../../search.svg" alt="busca" />
                            </button>
                          </div>
                        </div>


                      </div>



                    </div>

                    <div className='row px-0 bg-color-white'>
                      <div className='col-12 px-0' style={{ borderTop: 'solid 1px #d8d8d8' }} >
                        <DataTable
                          columns={columns}
                          data={arrCompanies}
                          pagination
                          responsive
                          noDataComponent="Sem registros para exibir"
                          highlightOnHover
                          conditionalRowStyles={conditionalRowStyles}
                          customStyles={customStyles}
                          paginationComponent={BootyPagination}
                        />
                      </div>
                    </div>

                  </div>

                </div>

              }

            </>

          )}

        </>
      }


      {details &&
        <div className='bg-color-ui-1 pt-5 pb-5 wp-home mt-4'>
          <div className='container'>
            <div style={{ paddingBottom: 10, fontSize: "14pt", color: "#0c9ae3", cursor: "pointer" }} onClick={() => setDetails(null)}>&lt; Voltar para o resultado de busca múltipla</div>


            <div className='row bg-color-white py-2 px-4 rounded-lg' style={{ width: '100%' }}>
              <div className='col-12' style={{ width: '100%', overflow: 'hidden' }}>

                {item.error && (

                  <div className='row'>

                    <Warning
                      type='error'
                      showpick={false}
                      title={"Erro ao buscar GLN"}
                      text={"O numero informado não pode ser encontrado na base de dados"}
                    ></Warning>

                  </div>

                )}

                {item.content && (

                  <>

                    <OtherKeyResults data={details}  keyType={keyType?.typeKey || ''} code={code} />


                  </>

                )}

              </div>
            </div>
          </div>
        </div>

      }




<Modal
        isOpen={stateModal}
        onRequestClose={close}
        ariaHideApp={false}
        contentLabel="Selected Option"
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#262626CC",
            zIndex: 1000,
          },
          content: {
            width: "370px",
            height: "330px",
            marginTop: "10%",
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: 1000,
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div style={{ padding: "0.5rem" }}>
          <span
            onClick={() => close()}
            style={{ position: "absolute", right: "7px", top: "7px" }}
          >
            <IoMdClose />
          </span>
          <div
            style={{ display: "flex", flexDirection: "column", rowGap: "2rem" }}
          >
            <span
              style={{
                color: "#002C6C",
                fontSize: "1.375rem",
                marginBottom: "-25px",
              }}
            >
              Exportar Arquivo
            </span>
            <span
              style={{
                color: "#002C6C",
                fontSize: "13px",
                marginBottom: "-5px",
                fontWeight: "400"
              }}>
              Escolha o formato de arquivo para exportarc
            </span>
            <span
              style={{
                fontSize: "13px",
                marginBottom: "-20px"
              }}
            >
              Formatos de arquivos
            </span>
            <span
              style={{
                fontSize: "14px",
                display: "felx",
                flexDirection: "column"
              }}
            >
              <div>
                <div style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  marginBottom: "5px"
                }}>
                  <input
                    type="radio"
                    value="txt"
                    name="extension"
                    checked={extension === 'txt'}
                    onChange={handleChangeExtension}

                  /> &nbsp; .txt
                </div>
                <div style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  marginBottom: "5px"
                }}>
                  <input
                    type="radio"
                    value="xls"
                    name="extension"
                    checked={extension === 'xls'}
                    onChange={handleChangeExtension}
                  /> &nbsp; .xls
                </div>
                <div style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  marginBottom: "5px"
                }}>
                  <input
                    type="radio"
                    value="csv"
                    name="extension"
                    checked={extension === 'csv'}
                    onChange={handleChangeExtension}
                  /> &nbsp; .csv
                </div>
              </div>
            </span>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: 10,
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >

              <button disabled={exportButtonStatus} onClick={() => exportFiles()} className="btn btn-primary btn-sm">
                Exportar
              </button>
              <button disabled={exportButtonStatus} onClick={() => close()} className="btn btn-outline-primary btn-sm">
                Cancelar
              </button>

            </div>
          </div>
        </div>

      </Modal>
    </>
  );
};

export default SearchManyOtherKeys;



